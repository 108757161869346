import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExamModal from "../pannel/examModal";
import api from "../../API/api";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItemsMaster from "../pannel/menuitemmaster";
import DialogDynamic from "../pannel/dialog";
import CreatePaper from "../Paper/createPaper";
import DialogDynamicMaster from "../pannel/dialogueModalMaster";
import { useSelector } from "react-redux";

const AddExamName = () => {
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const [organization, setOrganization] = useState([]);
  const [examType, setExamType] = useState();
  const [preview, setPreview] = useState("");
  const [getRow, setGetRow] = useState({});
  const [openPaper, setOpenPaper] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = useState(false);
  const [nameData, setNameData] = useState([]);
  const [name, setName] = useState({
    id: "",
    examName: "",
    institute: "",
    specialization: "",
    grade: "",
    image: "",
  });

  // redux data
  const getExamTypes = useSelector((state) => state.ExamsFetchReducer);
  // redux data

  const getOrganizationsByid = (id) => {
    api
      .getOrganizationById(id)
      .then((res) => {
        console.log(res.data, "check redsds");
        setOrganization(res.data.organization);
        setExamType(res.data.examType);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const viewData = () => {
    api
      .getExamType()
      .then((res) => {
        console.log(res, "dasgb");
        setExamData(res.data.examtype);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // axios
    //   .get(`${process.env.REACT_APP_MAIN_API}/getExamType`)
    //   .then((res) => {
    //     console.log(res);
    //     setExamData(res.data.examtype);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  };

  useEffect(() => {
    viewName();
  }, []);

  const viewName = () => {
    api
      .getExamName()
      .then((res) => {
        setLoading(false);
        console.log(res, "get exams");
        setNameData(res.data.examname);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName({});
    setUpdate(false);
  };

  // handle examtype onchange
  const handleInput = (e) => {
    setName({
      ...name,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeExamType = (e) => {
    console.log(e.target.name, e.target.value, "check exam type");

    setName({
      ...name,
      [e.target.name]: e.target.value,
    });
    getOrganizationsByid(e.target.value);
  };

  const onChanegOrganization = (e) => {
    console.log(e.target.value, "organizaiton value");

    setName({
      ...name,
      [e.target.name]: e.target.value,
    });
  };
  const handleImageUpload = (e) => {
    console.log(e.target.files[0], "uplaoded image");
    setName({
      ...name,
      [e.target.name]: e.target.files[0],
    });
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  // submit exam name create modal
  const handleSubmit = () => {
    if (!name.examtype) {
      Swal.fire("Error", "Exam Type cannot be blank", "error");
      return;
    }
    if (!name.institute) {
      Swal.fire("Error", "Organization /instituion cannot be blank", "error");
      return;
    }

    const examData = new FormData();
    // console.log(name , 'check exam anme')
    // return;
    examData.append("examtype", name.examtype);
    examData.append("examName", name.examName);
    examData.append("institute", name.institute);
    examData.append("specialization", name.specialization);
    examData.append("grade", name.grade);
    examData.append("image", name.image);

    api
      .postExamName(examData)
      .then((res) => {
        console.log(res);
        viewName();
        handleClose();
        Swal.fire("Good job!", "Registered Successfully!", "success");
      })
      .catch((err) => {
        console.log(err.response);
      });

    // axios
    //   .post(`${process.env.REACT_APP_MAIN_API}/postExamName`, postData)
    //   .then((res) => {
    //     console.log(res);
    //     viewName();
    //     handleClose();
    //     Swal.fire("Good job!", "Registered Successfully!", "success");
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  };

  const handleEdit = (e, data) => {
    handleOpen();
    setUpdate(true);
  };
  const handleDisable = (e, data) => {};
  const openCreatePaper = () => {
    setOpenPaper(true);
  };

  const handleUpdate = (id) => {
    // console.log(name, "dasudsagbds");

    // if (name.institute.trim() === null) {
    //   alert("organization is required");
    //   return;
    // }

    // return;
    const examData = new FormData();

    examData.append("id", id);
    examData.append("examName", name.examName);
    examData.append("institute", name.institute);
    examData.append("specialization", name.specialization);
    examData.append("grade", name.grade);
    if (name.image) {
      examData.append("image", name.image);
    }
    examData.append("examtype", name.examtype);

    console.log(name, "name");

    // return;
    api
      .updateExamData(examData)
      .then((res) => {
        console.log(res);
        viewName();
        handleClose();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  // const examDataById = (id) => {
  //   console.log("working");
  //   api
  //     .examDataById(id)
  //     .then((res) => {
  //       console.log(res, "whats happening");
  //       setName(res.data.examname);
  //       setUpdate(true);
  //       handleOpen();
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //     });
  // };

  useEffect(() => {
    viewData();
    setTimeout(() => {
      console.log(getExamTypes && getExamTypes, "getExamTypes");
    }, [1000]);
  }, []);

  useEffect(() => {
    // setName(getRow);
    setName({
      ...getRow,
      image: "",
    });
  }, [update]);
  const menuItems = [
    {
      id: 1,
      name: "Edit",
      path: "",
      func: handleEdit,
      data: "",
    },
    {
      id: 2,
      name: "Disable",
      path: "",
      func: handleDisable,
      data: "",
    },
    {
      id: 3,
      name: "add paper",
      path: "",
      func: openCreatePaper,
      data: "",
    },
  ];
  return (
    <>
      {loading ? (
        <>
          <LinearProgress color="secondary" />
          <Loading />
        </>
      ) : (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  className="my-3"
                  style={{ float: "right" }}
                  size="small"
                >
                  + Add Exam Name
                </Button>
                <ExamModal
                  handleInput={handleInput}
                  handleImageUpload={handleImageUpload}
                  name={name}
                  examData={examData}
                  organization={organization}
                  handleSubmit={handleSubmit}
                  open={open}
                  handleClose={handleClose}
                  update={update}
                  handleUpdate={handleUpdate}
                  getExamTypes={getExamTypes}
                  onChanegOrganization={onChanegOrganization}
                  onChangeExamType={onChangeExamType}
                  preview={preview}
                />
              </div>
              {openPaper && (
                <DialogDynamicMaster
                  open={openPaper}
                  setOpen={setOpenPaper}
                  buttonText={<Button variant="contained">i</Button>}
                  maxWidth="lg"
                  formTitle="Add paper exam wise"
                  handleClickOpen={() => setOpenPaper(true)}
                  // data={row}
                  form={<CreatePaper getRow={getRow} />}
                  handleClose={() => setOpenPaper(false)}
                  outsideClickHide={false}
                />
              )}

              <div className="col-lg-12">
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: "100%" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">ID</StyledTableCell>
                          <StyledTableCell align="center">
                            Exam Name
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Organisation Board
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Exam Type
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Grade
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Specialization
                          </StyledTableCell>
                          <StyledTableCell align="center">Icon</StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {nameData.map((row) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {row.id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.examName}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.organization?.organization_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.exam?.examType}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.grade}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.specialization}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <img
                                src={`${process.env.REACT_APP_BACKEND}exam/icons/${row.image}`}
                                height="50px"
                                width="100px"
                                style={{ objectFit: "contain" }}
                              />
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <MenuItemsMaster
                                setGetRow={setGetRow}
                                row={row}
                                menuItems={menuItems}
                                open={openMenu}
                                setOpenMenu={setOpenMenu}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AddExamName;

const Loading = () => {
  return (
    <>
      <div className="container p-5">
        <div className="row">
          <div className="col-md-12">
            <div className="p-4 row">
              <div className="col-md-12">
                <Skeleton
                  height={35}
                  width={200}
                  count={1}
                  style={{ marginBottom: 10, float: "right" }}
                />
              </div>
              <div className="col-md-12">
                <Skeleton height={35} count={1} style={{ marginBottom: 6 }} />
              </div>
              <div className="col-md-4 my-2">
                <Skeleton height={30} count={10} style={{ marginBottom: 6 }} />
              </div>
              <div className="col-md-4 my-2">
                <Skeleton height={30} count={10} style={{ marginBottom: 6 }} />
              </div>
              <div className="col-md-4 my-2">
                <Skeleton height={30} count={10} style={{ marginBottom: 6 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
